import React from "react"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import { graphql, Link } from "gatsby"
import { Text } from "@atoms"
import { InfoSocialShareRow } from "../components/Resources/InfographicSocialShareRow"
import ConversionPanelStandard from "@components/ConversionPanel/ConversionPanelStandard"
import {
  Heading,
  Kicker,
  HeroWrapper,
  GraphicSectionWrapper,
  BreadCrumbFlex,
  IconImg,
  ThumbnailWrapper,
} from "./styles/infographicDetail.styled"
import OptimizedImage from "../utils/optimizedImage"

const InfographicDetailTemplate = ({ data }) => {
  const postData = data?.allContentfulResource?.edges[0]?.node
  const conversionPanel = data?.contentfulComponentConversionPanel
  const seo = postData?.pageSeo
  const heroImage = data?.contentfulComponentImage

  const breadStyle = {
    fontWeight: 400,
    textDecoration: "none",
    color: "white",
    transition: "color 200ms ease",
  }

  return (
    <Layout headerTheme={{ theme: "dark", background: "transparent" }}>
      <Seo
        title={seo?.seoTitle || postData?.title}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        image={seo?.graphImage?.file?.url || heroImage?.primaryImage?.file?.url}
        imageAlt={seo?.graphImage?.description}
        metaTags={seo?.metaTags}
        article={seo?.article}
      />
      <HeroWrapper>
        <OptimizedImage
          className="hero-image"
          loading="eager"
          image={heroImage?.primaryImage?.gatsbyImageData}
          src={heroImage?.primaryImage?.file?.url}
          alt=""
        />
        <BreadCrumbFlex>
          <Link href="/">
            <span style={breadStyle}>Home</span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-white.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <Link href="/resources/resource-hub">
            <span style={breadStyle}>
              <p>Resources</p>
            </span>
          </Link>
          <IconImg>
            <img
              src="/icons/chevron-right-white.svg"
              alt="Breadcrumb Chevron"
            />
            <img
              src="/icons/chevron-right-black.svg"
              alt="Breadcrumb Chevron"
            />
          </IconImg>
          <Link href="/resources/resource-hub">
            <span style={breadStyle}>
              <p>Infographic</p>
            </span>
          </Link>
        </BreadCrumbFlex>
        <Kicker>INFOGRAPHIC</Kicker>
        <Heading className="heading">{postData?.title}</Heading>
        {postData?.description?.description && (
          <Text className="description" textVariant={"light"}>
            {postData?.description?.description}
          </Text>
        )}
      </HeroWrapper>
      <GraphicSectionWrapper>
        <ThumbnailWrapper>
          <figure className="image-content">
            {postData?.featuredImage?.file?.url ? (
              <div>
                <OptimizedImage
                  image={postData?.featuredImage?.gatsbyImageData}
                  src={postData?.featuredImage?.file?.url}
                  alt={postData?.title || "infographic"}
                />
              </div>
            ) : (
              <div className="emptyImg" />
            )}
          </figure>
        </ThumbnailWrapper>
        <InfoSocialShareRow
          slug={postData.slug}
          iconColor={"blue"}
          backgroundColor={"#F2F4F7"}
          download={postData?.featuredImage?.file?.url}
        />
      </GraphicSectionWrapper>
      <ConversionPanelStandard component={conversionPanel} />
    </Layout>
  )
}

export default InfographicDetailTemplate

export const InfographicTemplateQuery = graphql`
  query InfographicTemplateQuery($id: String) {
    contentfulComponentConversionPanel(
      id: { eq: "182594f4-83ef-5663-a500-ad9e6e8e904e" }
    ) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      subheading {
        raw
      }
      noTopPadding
      noBottomPadding
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      type
    }
    allContentfulResource(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          pageSeo {
            graphImage {
              description
              title
              file {
                url
              }
            }
            seoTitle
            seoDescription
            canonical
            noIndex
            metaTags
            article
          }
          publishDate
          externalUrl
          category {
            title
            backgroundColor
          }
          featuredImage {
            gatsbyImageData
            file {
              url
            }
          }
          description {
            description
          }
          body {
            raw
          }
        }
      }
    }
    contentfulComponentImage(
      id: { eq: "60f0bae9-f25a-5834-85ea-25a579e669ea" }
    ) {
      __typename
      id
      primaryImage {
        gatsbyImageData
        file {
          url
        }
        description
      }
    }
  }
`
